@import '../styles/STYLING_PARAMS.scss';


.MonchNavBar {
  background-color: var(--background-plain);
  height: 50px;
  box-shadow: 0 0 10px var(--shadow-regular);
  width: 100%;

  /*This makes the bar floating*/
  position: fixed;
  z-index: 100;
}

.monch-nav-section {
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;

  color: var(--text-light);
}


#monch-nav-search {
  width: 25%;
  float: left;
}

#monch-nav-search i {
  font-size: 24px;
}



#monch-nav-options {
  width: 25%;
  float: right;

  text-align: right;

  font-size: 24px;
}

#monch-nav-logo {
  width: 100%;
  text-align: center;
  float: none;

  font-size: 28px;
  font-weight: 600;
  color: var(--accent-primary);
}


#search_term {
  height: 36px;
  margin-top: 7px;
}


i:hover {
  color: var(--text-medium);
  cursor: pointer;
}

#monch-nav-logo:hover {
  cursor: pointer;
}


.language-toggle.selected {
  color: var(--text-medium);
  font-weight: 700;

}
.language-toggle:hover {
  color: var(--text-medium);
  cursor: pointer;
}

.ProfileDropdown button {
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 40px;
  width: 60px;
  line-height: 24px;

  font-size: 14px;

  background-color: var(--text-medium);
  opacity: 0.9;
}







