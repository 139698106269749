:root {
	--background-plain: #FFFFFF;
	--dark-gray: #313131;
	--yellow: #FFC14B;
	--light-blue: #67B5C7;
	--bright-blue: #3db1ff;
	--light-orange: #EB8F60;
	--light-gray: #787878;
	--super-light-gray: #b8b8b8;
	--dark-white: #f0f0f0;


	--accent-primary: #E97E49;
	--accent-primary-inactive: #B36037;
	--accent-secondary: #67B5C7;
	--accent-secondary-inactive: #4a828f;
	--accent-secondary-shadow: rgba(103,181,199,.15);
	--text-light: #B2A587;
	--text-medium: #756c58;
	--shadow-regular: #E3DBCD;
	--shadow-dark: #59564f;
	--font-color: black;


	--default-font-size: 16px;

	--button-height-large: 45px;
	--button-height-xlarge: 65px;
	--button-radius-xlarge: 20px;

	/* Progress Bar */
	--progress-bar-height: 40px;
	--progress-bar-height-half: 20px;
	--progress-bar-height-small: 30px;
	--progress-bar-line-height-small: 26px;
	--progress-bar-height-half-small: 15px;

	/* survey */
	--survey-input-width-xxsmall: 55px;
	--survey-input-width-xsmall: 75px;
	--survey-input-width-small: 100px;
	--survey-input-width-smallmedium: 150px;
	--survey-input-width-medium: 200px;
	--survey-input-width-mediumlarge: 250px;
	--survey-input-width-large: 300px;

}