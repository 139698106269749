/*
* === General Style ===
*/

.Profile.XSmall {
	font-size: 15px;
}

.Profile .bold-text {
	font-weight: 600;
	color: var(--dark-gray);
}

.Profile .error-message {
	color: red;
}
.Profile .alert-text {
	color: var(--bright-blue);
	font-size: 18px;
	font-weight: 700;
	font-style: italic;
}

.Profile.XSmall .alert-text {
	font-size: 17px;
}

.Profile .plan-heading {
	font-weight: 600;
	font-size: 17px;
	color: var(--dark-gray);
}

.Profile.SmallUp .emphasized-orange {
	font-size: 18px;
}

.Profile.XSmall .plan-heading, 
.Profile.XSmall .emphasized-orange,
.Profile.XSmall a {
	font-size: 16px;
}

.Profile .file-input {
	/* default file upload button is hidden */
	display: none; 
}

.Profile .custom-file-upload {
	color: var(--dark-gray);
	background-color: var(--light-blue);
	height: 50px;
	width: 225px;
	font-size: inherit;
	line-height: 16px;
	padding: 7px;
	padding-right: 15px;
	padding-left: 15px;
	border-radius: 5px;
	display: table-cell;
	vertical-align: middle;
}

.Profile .custom-file-upload.already-uploaded {
	background-color: var(--dark-white);
	box-shadow: none;
}

.Profile .agree-to-terms {
	font-size: 18px;

}
.Profile .i-agree {
	font-size: 14px;
	line-height: 18px;
	padding: 15px;
	background-color: var(--light-blue);
	color: var(--dark-gray);
}
