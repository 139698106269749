@import './styles/STYLING_PARAMS.scss';

.App {
  background-color: var(--background-plain);
}

.AppContent {
  width: 100%;
  padding: 10px;
  padding-top: 60px;
  min-height: 90vh;
}

.ContentPage {
  padding: 50px;
  min-height: 500px;
}

h1, h2, h3, h4, h5, h6 {
  font-color: var(--font-color);
}

.NoPermission {
  padding: 100px;
  height: 100vh;
}

.emphasized-blue, .emphasized-blue-link {
  font-weight: 600;
  color: var(--accent-secondary);
}

.emphasized-orange, .emphasized-orange-link {
  font-weight: 600;
  color: var(--accent-primary);
}

.emphasized-blue-link:hover, .emphasized-blue-link:focus {
  color: var(--accent-secondary-inactive);
  text-decoration: underline;
  cursor: pointer;
}

/* Spinning Loading Icon */
.FullPageSpinnerContainer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20vh;
}

.spinning {
  margin: 0 auto;
  top: 2px;
  animation: spin 2.5s infinite linear;
  color: var(--shadow-regular);
}

.spinning.dark{
  margin: 0 auto;
  top: 2px;
  animation: spin 2.5s infinite linear;
  color: var(--dark-gray);
}

.qr-code {
  width: 150px;
  height: 150px;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.indented-div {
  margin-left: 45px;
}




