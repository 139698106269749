.CardCheckout .stripe-cc-info-container{
	border: 1px solid #e1e5eb;
	padding: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 5px;
}

.CardCheckout .stripe-cc-info-container:hover{
	border-color: var(--accent-secondary);
	box-shadow: 0 0.313rem 0.719rem var(--accent-secondary-shadow), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

#reminder-modal .modal-header,
#reminder-modal .modal-footer {
	border: 0;
	height: 40px;
}

#reminder-modal .modal-content {
	background-color: rgba(255, 255, 255, 0.98);
}

#reminder-modal p {
	text-align: center;
	padding: 0;
	margin: 0;
}