/*
* === General Style & Positioning ===
*/

.Home {
	max-height: 1200px;
	height: 100%;
	color: var(--dark-gray);
	background-color: var(--light-blue);
	display: flex;
	justify-content: center;	
}

.Home.SmallUp {
	padding: 50px;
	font-size: 18px;
}

.Home.XSmall {
	padding: 0;
	padding-left: 20px;
	padding-right: 15px;
	font-size: 16px;
}

.Home.SmallUp .home-content {
	min-width: 800px;
}

.Home.SmallUp .slogan-area {
	padding-top: 10px;
	text-align: center;
}

.Home.XSmall .slogan-area {
	padding-top: 0px;
	text-align: left;
}

.Home .slogan {
	width: 100%;
	font-weight: 600;
}


/*
* === Slogans Chinese ===
*/

.Home.SmallUp #slogan-1-chinese {
	font-size: 66px;
	letter-spacing: 14px;
}

.Home.XSmall #slogan-1-chinese {
	font-size: 32px;
	letter-spacing: 4px;
}

.Home.SmallUp #slogan-2-chinese {
	font-size: 48px;
	letter-spacing: 14.5px;
}

.Home.XSmall #slogan-2-chinese {
	font-size: 30px;
	letter-spacing: 2px;
}

.Home.SmallUp #slogan-3-chinese {
	margin-top: 5px;
	font-size: 18px;
	letter-spacing: 3.5px;
}

.Home.XSmall #slogan-3-chinese {
	margin-top: 2px;
	font-size: 15px;
	letter-spacing: 1.5px;
}

.Home.SmallUp #services-chinese {
	margin-top: 50px;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 7px;
}

.Home.XSmall #services-chinese {
	margin-top: 50px;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 1px;
}

/*
* === Slogans English ===
*/

.Home.SmallUp #slogan-1-english {
	font-size: 58px;
	letter-spacing: 3.8px;
}

.Home.XSmall #slogan-1-english {
	font-size: 34px;
	letter-spacing: 4.2px;
	line-height: 37px;
}

.Home.SmallUp #slogan-2-english {
	margin-top: -10px;
	font-size: 58px;
	letter-spacing: 5px;
}

.Home.XSmall #slogan-2-english {
	margin-top: -3px;
	font-size: 31px;
	letter-spacing: 1px;
}

.Home.SmallUp #slogan-3-english {
	margin-top: 5px;
	letter-spacing: -0.2px;
}

.Home.XSmall #slogan-3-english {
	margin-top: 2px;
	font-size: 14px;
	letter-spacing: 0.9px;
}

.Home.SmallUp #services-english {
	margin-top: 40px;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.85px;
}

.Home.XSmall #services-english {
	margin-top: 50px;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.0px;
}

.Home.SmallUp .dark-button {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 25px;
	padding-bottom: 25px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 22px;
	font-weight: 650;
}

.Home.XSmall .dark-button {
	font-size: 18px;
	padding-left: 20px;
	padding-right: 20px;
}

.Home .alt-get-started-options {
	height: 100%;
	width: 99%;
	max-width: 520px;
	background-color: var(--dark-white);
	color: var(--dark-gray);
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
} 

.Home .alt-get-started-options:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
} 

.Home .alt-get-started-options .helptext {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
} 

.Home.XSmall .alt-get-started-options .helptext {
	text-align: left;
	font-size: 16px;
}

.Home .flashing-icon {
	animation: flashingicon 800ms infinite;
}

@keyframes flashingicon {
    0% { 
    	color: white;
    }
    50% {
    	color: var(--yellow);
    }

    100% {
    	color: white;
    }
}



