.PlanSignup {
	max-width: 750px;
	margin: auto;
}

.PlanSignup .SliderBar {
	width: 100%;
	height: 40px;
	border-radius: 20px;

	font-size: 16px;
	font-weight: 600;
	color: white;

	text-align: center;
	line-height: 40px;
}

.PlanSignup .inline-button {
	margin-left: 10px;
	height: 30px;
	padding: 0;
	padding-left: 10px;
	padding-right: 10px;
	background-color: var(--light-orange);
	font-size: 14px;
}
.PlanSignup .SliderBar .SliderComponent {
	width: 50%;
	height: 100%;
	background-color: gray;
}

.PlanSignup .SliderBar .SliderComponent.Selected {
	background-color: var(--light-blue);
}


.PlanSignup .SliderBar .SliderComponent.Left {
	border-radius: 20px 0px 0px 20px;
	float: left;
}

.PlanSignup .SliderBar .SliderComponent.Right {
	border-radius: 0px 20px 20px 0px;
	float: right;
}

.PlanSignup .PlanOverview {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 8%;
	padding-right: 8%;

	background-color: white;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.PlanSignup .PlanOverview:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	cursor: pointer;
}

.PlanSignup .PlanOverview select,
.PlanSignup .PlanOverview input {
	height: 40px;
	border: 1px solid var(--shadow-regular);
	color: var(--shadow-dark);
	width: 100%;
	max-width: 325px;
	padding-left: 8px;
	padding-right: 8px;
}

.PlanSignup .PlanOverview select {
	padding-left: 2px;
	padding-right: 2px;
	height: 50px;
	font-size: 16px;
}

.PlanSignup .plan-heading {
	font-weight: 600;
	font-size: 18px;
	color: var(--dark-gray);
}

.PlanSignup .bold-text {
	font-weight: 600;
	color: var(--dark-gray);
	line-height: 24px;
}

.PlanSignup .input-label {
	display: inline-block;
	width: 200px;
}

.PlanSignup .input-label.medium {
	width: 200px;
	line-height: 24px;
}

.PlanSignup .input-label.medium.tall {
	line-height: 50px;
}


.PlanSignup .error-message {
	color: red;
}

.PlanSignup input, .PlanSignup textarea, .PlanSignup select {
	width: 65%;
}

.PlanSignup .short-input {
	width: 150px;
}

.supported-cards {
	width: 250px;
	margin-top: 15px;
	margin-bottom: 10px;
}


