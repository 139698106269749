.ChoosePlan {
	margin: auto;
}

.ChoosePlan .SmallDown {
	font-size: 14px;
}

.ChoosePlan .MediumUp {
	padding-top: 7px;
	max-width: 800px;
	margin: auto;
}

.ChoosePlan .SmallDown ul {
	padding-left: 17px;

}

.ChoosePlan .MediumUp .plan-cards {
	width: 100%;
	height: 140px;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.ChoosePlan .SmallDown .card-container {
	margin-bottom: 10px;
}

.ChoosePlan .bold-text {
	font-size: 20px;
	font-weight: 600;
	color: var(--dark-gray);
}

.ChoosePlan .big-plan-price {
	color: var(--dark-gray);
	font-size: 72px;
}

.ChoosePlan .SmallDown .big-plan-price {
	color: var(--dark-gray);
	font-size: 58px;
}

.ChoosePlan .big-plan-price-superscript {
	color: var(--dark-gray);
	font-size: 32px;
}

.ChoosePlan .SmallDown .big-plan-price-superscript {
	font-size: 24px;
}

.ChoosePlan .small-plan-price-caption {
	font-size: 22px;
}

.ChoosePlan .SmallDown .small-plan-price-caption {
	font-size: 18px;
}

.ChoosePlan .plan-highlights {
	margin-left: 5px;
	margin-top: -10px;
	font-size: 16px;
	color: var(--dark-gray);

	line-height: 16px;
}

.ChoosePlan .plan-highlights p {
	margin-bottom: 7px;

}

.ChoosePlan .SmallDown .plan-highlights {
	font-size: 12px;
	line-height: 13px;
	margin-top: -6px;
	margin-left: 4px;
}


.ChoosePlan .MediumUp .card-container {
	width: 30%;
	height: 100%;
}


.ChoosePlan .icon-box {
	width: 350px;
	justify-content: space-between;
	padding-left: 0;
	cursor: pointer;
}

.ChoosePlan .SmallDown .icon-box {
	width: 100%;
	justify-content: space-between;
	padding-left: 0;
	cursor: pointer;
}