.Card {
	color: var(--dark-gray);

	width: 100%;
	height: 100%;

	padding: 20px;
	padding-left: 5%;
	padding-right: 5%;

	text-align: center;
	background-color: white;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.Card:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.Card .text {
	margin-top: 0;
}

.Card .text .plan-duration {
	font-size: 22px;
	font-weight: 700;
	width: 100%;
	text-align: center;

}

.Card .text .deal-text {
	height: 3px;
	font-size: 13px;
	color: orange;
	font-style: italic;
	margin-top: -3px;
	margin-bottom: 5px;
}

.Card .select-plan-button {
	font-size: 16px;
	background-color: var(--light-blue);
	padding: 5px;
	width: 75%;
}

.Card .star-icon {
	color: orange;
}