.AlipayButton {
	background-color: white;
	padding: 5px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.AlipayButton:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

}

.AlipayButton .alipay-logo {
	width: 150px;
}

