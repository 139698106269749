@import '../styles/STYLING_PARAMS.scss';

.CustomFooter {
	text-align: center;
	font-size: 11px;
	color: white;
	background-color: var(--dark-gray);

	min-height: 80px;
	width: 100%;

	padding: 20px;
}

.footer-link {
	color: white;
}

.footer-link:hover {
	color: white;
	cursor: pointer;
	text-decoration: underline;
}